<template>
  <b-modal
      v-model="isOpen"
      size="md"
      :title="isCreateMode ? 'New сategory' : 'Edit category'"
      centered
      title-class="font-22"
      class="rules-modal"
      @hide="handleHideModal"
  >
    <b-form-group
        label="Category"
        label-for="category"
        class="required"
    >
      <b-form-input
          id="category"
          v-model="form.category"
          :class="{ 'is-invalid': $v.form.category.$error }"
      />
    </b-form-group>
    <b-form-group
        label="Type"
        label-for="type"
        class="required"
    >
      <multiselect
          v-model="form.type"
          id="type"
          class="app-multiselect"
          :class="{ 'is-invalid': $v.form.type.$error }"
          :options="typeOptions"
          :show-labels="false"
          :allow-empty="false"
          track-by="id"
          label="label"
          placeholder="Choose Type"
      />
    </b-form-group>
    <b-form-group
        label="Description"
        label-for="description"
    >
      <textarea
          type="text"
          v-model="form.description"
          id="description"
          rows="3"
          class="form-control"
      />
    </b-form-group>
    <div class="d-flex" style="gap: 20px">
      <b-form-group
          label="Auto Approval Below, $"
          label-for="min-price"
          class="required w-100"
      >
        <b-form-input
            id="min-price"
            v-model="form.minPrice"
            type="number"
            min="0"
            :class="{ 'is-invalid': $v.form.minPrice.$error }"
            lazy-formatter
            :formatter="formatNumberInputValue"
        />
      </b-form-group>
      <b-form-group
          label="CEO Approval Above, $"
          label-for="max-price"
          class="required w-100"
      >
        <b-form-input
            id="max-price"
            v-model="form.maxPrice"
            type="number"
            :min="0"
            :class="{ 'is-invalid': $v.form.maxPrice.$error }"
            lazy-formatter
            :formatter="formatNumberInputValue"
        />
      </b-form-group>
    </div>
    <b-form-group
        label="Approvers"
        class="required approvers mt-3"
        label-class="font-weight-500"
    >
      <div v-if="!$v.form.approvers.required && $v.form.approvers.$dirty" class="text-danger">Approvers can't be empty</div>
      <draggable
          v-model="form.approvers"
          handle=".approvers__drag-button"
          :disabled="form.approvers.length < 2"
      >
        <div
            v-for="(approveer, index) in form.approvers"
            :key="approveer.id"
            class="approvers__select"
        >
          <multiselect
              v-model="approveer.value"
              id="payer"
              class="app-multiselect"
              :class="{ 'is-invalid': $v.form.approvers.$each[index].value.$error }"
              :options="getApproverOptions(approveer.value)"
              :show-labels="false"
              :allow-empty="false"
              track-by="uid"
              label="name"
              placeholder="Choose Approver"
          />
          <i class="fas fa-equals approvers__drag-button" />
          <b-button
              variant="secondary"
              class="icon-button approvers__delete-button"
              @click="handleDeleteApprover(approveer.id)"
          >
            <i class="bx bxs-trash font-size-16" />
          </b-button>
        </div>
      </draggable>
      <b-button
          variant="secondary"
          class="icon-button approvers__add-button"
          @click="handleAddApprover"
      >
        <i class="bx bx-plus font-size-16" />
      </b-button>
    </b-form-group>
    <b-form-group
        label="Payer"
        label-for="payer"
        class="required"
    >
      <multiselect
          v-model="form.payer"
          id="payer"
          class="app-multiselect"
          :class="{ 'is-invalid': $v.form.payer.$error }"
          :options="employees"
          :show-labels="false"
          :allow-empty="false"
          track-by="uid"
          label="name"
          placeholder="Choose Payer"
      />
    </b-form-group>
    <b-form-group
        label="Executor"
        label-for="executor"
        class="required"
    >
      <multiselect
          v-model="form.executior"
          id="executor"
          class="app-multiselect"
          :class="{ 'is-invalid': $v.form.executior.$error }"
          :options="employees"
          :show-labels="false"
          :allow-empty="false"
          track-by="uid"
          label="name"
          placeholder="Choose Executor"
      />
    </b-form-group>
    <template slot="modal-footer">
      <div class="d-flex justify-content-end">
        <b-button
            variant="primary"
            class="rules-modal__submit-button"
            @click="handleSubmit"
        >
          Save
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
import draggable from 'vuedraggable';
import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import { FORM_MODE } from '@/utils/constants';
import { formatNumber } from '@/utils/numbers';
import { REQUISTION_TYPES } from '@/utils/constants';

const sortEmployees = (a, b) => a.name.localeCompare(b.name);

export default {
  components: { draggable },
  props: {
    value: Boolean,
    mode: String,
    initialData: Object
  },
  data() {
    return {
      form: {
        category: '',
        description: '',
        minPrice: null,
        maxPrice: null,
        approvers: [],
        type: '',
        payer: null,
        executior: null
      },
      typeOptions: [
        { id: REQUISTION_TYPES.REQUEST, label: 'Request' },
        { id: REQUISTION_TYPES.PURCHASE, label: 'Purchase' }
      ]
    }
  },
  validations() {
    return {
      form: {
        category: { required },
        minPrice: { required, minValue: minValue(0), maxValue: maxValue(this.form.maxPrice || 0) },
        maxPrice: { required, minValue: minValue(0) },
        type: { required },
        payer: { required },
        executior: { required },
        approvers: {
          required,
          $each: {
            value: { required }
          }
        }
      }
    }
  },
  methods: {
    handleSubmit() {
      this.$v.form.$touch();
      if (!this.$v.form.$anyError) {
        this.$emit('onSubmit', this.form);
        this.isOpen = false;
      }
    },
    handleDeleteApprover(id) {
      this.form.approvers = this.form.approvers.filter((item) => item.id !== id);
    },
    handleAddApprover() {
      this.form.approvers.push({ id: Date.now() + Math.random(), value: null })
    },
    handleHideModal() {
      this.form.category = '';
      this.form.type = '';
      this.form.description = '';
      this.form.minPrice = null;
      this.form.maxPrice = null;
      this.form.approvers = [];
      this.form.payer = null;
      this.form.executior = null;
      this.$v.$reset();
    },
    fillFormData() {
      const payer = this.employees.find(
          ({ uid }) => uid === this.initialData?.payer_employee_uid
      );
      const executior = this.employees.find(
          ({ uid }) => uid === this.initialData?.executior_employee_uid
      );
      const type = this.typeOptions.find(({ id }) => id === this.initialData?.type);
      this.form.type = type;
      this.form.category = this.initialData?.title ?? '';
      this.form.description = this.initialData?.description ?? '';
      this.form.minPrice = this.initialData?.min_price ?? null;
      this.form.maxPrice = this.initialData?.max_price ?? null;
      this.form.payer = payer ?? null;
      this.form.executior = executior ?? null;
      this.form.approvers = this.initialData?.approvers.map((item) => ({
        id: Date.now() + Math.random(),
        value: this.allEmployeesList.find(({ uid }) => uid === item.employee_uid) ?? null
      }));
    },
    getApproverOptions(currentValue) {
      if (currentValue) {
        const options = [...this.approvers, currentValue].sort(sortEmployees);

        return options.some(({ uid }) => uid === null)
            ? [{ uid: null, name: 'Hierarchy' }, ...options]
            : options;
      }
      return this.approvers;
    },
    formatNumberInputValue(value) {
      return formatNumber(value);
    },
  },
  computed: {
    employees() {
      return [...this.$store.state.employees.allEmployees].sort(sortEmployees);
    },
    allEmployeesList() {
      return [{ uid: null, name: 'Hierarchy' }, ...this.employees];
    },
    approvers() {
      return this.allEmployeesList
          .filter(({ uid }) => !this.form.approvers
              .some((item) => item.value?.uid === uid))
    },
    isOpen: {
      set(value) {
        this.$emit('input', value);
      },
      get() {
        return this.value;
      }
    },
    isCreateMode() {
      return this.mode === FORM_MODE.CREATE;
    }
  },
  watch: {
    isOpen(value) {
      if (value && this.initialData && !this.isCreateMode) {
        this.fillFormData();
      }
      if (value && this.isCreateMode) {
        this.handleAddApprover();
        this.$v.$reset();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.rules-modal {
  .app-multiselect {
    min-height: 36px;
  }
  &__submit-button {
    width: 94px;
  }
}
.approvers {
  &__select {
    display: grid;
    align-items: center;
    grid-column-gap: 15px;
    grid-template-columns: 1fr 16px 36px;
  }
  &__select + &__select {
    margin-top: 10px;
  }
  &__add-button,
  &__delete-button {
    width: 36px;
    height: 28px;
    margin-top: 10px;
    background-color: #E3E4E8;

    &:active {
      background-color: #E3E4E8 !important;
    }

    i {
      color: #2A3042;
    }
  }
  &__delete-button {
    height: 36px;
    margin-top: 0px;
  }
  &__drag-button {
    cursor: pointer;
    font-size: 16px;
  }
}
</style>
