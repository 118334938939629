<template>
  <layout :loading="loading">
    <div class="purchases">
      <div class="purchases__header d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center">
          <b-button
              variant="secondary"
              class="icon-button back-button"
              @click="goToPurchases"
          >
            <i class="bx bx-arrow-back font-size-16" />
          </b-button>
          <h1 class="title-1">Requisitions Categories</h1>
        </div>
        <b-button
            class="mr-2"
            variant="success"
            @click="handleOpenCreateModal"
        >
          <div class="d-flex align-items-center">
            <i class="bx bx-plus font-size-18 mr-2" />
            Add Category
          </div>
        </b-button>
      </div>
      <b-card class="purchases__container">
        <b-table
            :items="list"
            :fields="fields"
            class="purchases__table table"
            :tbody-tr-class="rowClass"
        >
          <template #cell(type)="{ value }">
            <span class="text-capitalize">{{ value }}</span>
          </template>
          <template #cell(min_price)="{ value }">
            {{ numberWithSpaces(value) }}
          </template>
          <template #cell(max_price)="{ value }">
            {{ numberWithSpaces(value) }}
          </template>
          <template #cell(approvers)="{ value, item }">
            <div class="d-flex justify-content-end" style="gap: 4px">
              <b-avatar
                  v-for="approver in value"
                  :key="`${item.id}${approver.employee_uid}`"
                  variant="info"
                  size="30px"
                  v-b-tooltip.hover
                  :title="approver?.employee?.name || 'Hierarchy'"
                  :text="getNameInitials(approver?.employee?.name)"
                  :src="approver?.employee?.photo"
              >
                <i v-if="approver.employee_uid === null" class="bx bx-sitemap" />
              </b-avatar>
              <b-avatar
                  v-for="approver in [item.payment_employee, item.executior_employee]"
                  :key="approver.id"
                  variant="info"
                  size="30px"
                  v-b-tooltip.hover
                  :title="approver.name"
                  :text="getNameInitials(approver.name)"
                  :src="approver.photo"
              />
            </div>
          </template>
          <template #cell(actions)="{ item }">
            <b-dropdown
                right
                class="purchases__dropdown"
            >
              <template #button-content>
                <i class="bx bx-dots-vertical-rounded" />
              </template>
              <b-dropdown-item @click="onEditIconClick(item)">
                <div class="d-flex align-items-center">
                  <i class="bx bx-pencil font-size-16" />
                  Edit
                </div>
              </b-dropdown-item>
              <b-dropdown-item @click="onDeleteIconClick(item.id)">
                <div class="d-flex align-items-center">
                  <i class="bx bx-trash font-size-16" />
                  Delete
                </div>
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item
                  v-if="item.available"
                  @click="handleChangeCategoryStatus(item, false)"
              >
                <div class="d-flex align-items-center">
                  <i class="bx bx-pause-circle font-size-16" />
                  Pause
                </div>
              </b-dropdown-item>
              <b-dropdown-item
                  v-else
                  @click="handleChangeCategoryStatus(item, true)"
              >
                <div class="d-flex align-items-center">
                  <i class="bx bx-play-circle font-size-16" />
                  Resume
                </div>
              </b-dropdown-item>
            </b-dropdown>
          </template>
        </b-table>
      </b-card>
    </div>
    <category-modal
        v-model="isModalOpen"
        :mode="mode"
        :initialData="formInitialData"
        @onSubmit="handleSubmitForm"
    />
  </Layout>
</template>

<script>
import { mapActions } from 'vuex';
import { FORM_MODE } from '@/utils/constants';
import { getNameInitials } from '@/utils/user';
import { numberWithSpaces } from '@/utils/numbers';
import CategoryModal from '@/components/purchases/сategory-modal.vue';

const createRequestData = (data) => ({
  title: data.category,
  type: data.type.id,
  description: data.description,
  min_price: data.minPrice,
  max_price: data.maxPrice,
  payer_employee_uid: data.payer.uid,
  executior_employee_uid: data.executior.uid,
  approvers: data.approvers.map(({ value }, index) => ({
    order: index + 1,
    employee_uid: value.uid
  })),
  hierarchy: false,
  available: true
});

const createRequestDataFromRecord = (data) => ({
  title: data.title,
  type: data.type,
  description: data.description,
  min_price: data.min_price,
  max_price: data.max_price,
  payer_employee_uid: data.payer_employee_uid,
  executior_employee_uid: data.executior_employee_uid,
  approvers: data.approvers.map((item, index) => ({
    order: index + 1,
    employee_uid: item.employee_uid
  })),
  hierarchy: false,
  available: data.available,
  usd_only: data.usd_only,
});

export default {
  components: {
    CategoryModal,
  },
  data() {
    return {
      tabIndex: 0,
      loading: false,
      fields: [
        { key: 'title', label: 'All Categories' },
        { key: 'type', label: 'Type' },
        { key: 'min_price', label: 'Auto Approval Below, $' },
        { key: 'max_price', label: 'CEO Approval Above, $' },
        { key: 'approvers', label: 'Stakeholders', thClass: 'text-right', tdClass: 'table__stackholders' },
        { key: 'actions', label: '', tdClass: 'table__actions', thStyle: { width: '44px' } }
      ],
      isModalOpen: false,
      formInitialData: null,
      mode: FORM_MODE.CREATE
    };
  },
  async mounted() {
    this.loading = true;
    await this.getPurchaseCategories();
    await this.getAllEmployees();
    this.loading = false;
  },
  methods: {
    getNameInitials,
    numberWithSpaces,
    ...mapActions('purchases', [
      'getPurchaseCategories',
      'createPurchaseCategory',
      'updatePurchaseCategory',
      'deletePurchaseCategory'
    ]),
    ...mapActions('employees', ['getAllEmployees']),
    openRulesPage() {
      this.$router.push({ name: 'purchase-rules' });
    },
    onDeleteIconClick(id) {
      this.$bvModal.msgBoxConfirm('Delete this item?', {
        title: 'Please Confirm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
          .then(async (value) => {
            if (value) {
              this.loading = true;
              await this.deletePurchaseCategory(id);
              await this.getPurchaseCategories();
              this.loading = false;
            }
          });
    },
    onEditIconClick(data) {
      this.formInitialData = data;
      this.mode = FORM_MODE.EDIT;
      this.isModalOpen = true;
    },
    handleOpenCreateModal() {
      this.mode = FORM_MODE.CREATE;
      this.isModalOpen = true;
    },
    async handleSubmitForm(data) {
      const requestData = createRequestData(data);
      this.loading = true;
      if (this.mode === FORM_MODE.CREATE) {
        await this.createPurchaseCategory(requestData);
      } else {
        await this.updatePurchaseCategory({
          id: this.formInitialData?.id,
          data: { ...requestData, available: this.formInitialData?.available }
        });
      }
      await this.getPurchaseCategories();
      this.loading = false;
    },
    goToPurchases() {
      this.$router.push({ name: 'requisitions' });
    },
    async handleChangeCategoryStatus(item, available) {
      this.loading = true;
      await this.updatePurchaseCategory({
        id: item.id,
        data: createRequestDataFromRecord({ ...item, available })
      });
      await this.getPurchaseCategories();
      this.loading = false;
    },
    rowClass({ available }) {
      return available ? '' : 'table__disabled-row';
    }
  },
  computed: {
    list() {
      return this.$store.state.purchases.categories;
    }
  }
};
</script>

<style lang="scss" scoped>
:deep(.nav-tabs) {
  padding-left: 15px;

  .nav-link {
    padding: 8px 10px;

    &.active {
      background-color: transparent;
    }
  }
}

.purchases {
  &__header {
    margin-bottom: 20px;
  }

  &__settings-button {
    width: 36.53px;
    height: 36.53px;
    position: relative;

    i {
      top: 50%;
      left: 50%;
      font-size: 16px;
      position: absolute;
      transform: translate(-50%, -50%);
    }
  }

  &__container {
    overflow: hidden;

    .card-body {
      padding: 0px;
    }
  }

  &__table {
    :deep(th) {
      color: #2A3042;
      font-weight: 500;
      line-height: 20px;
      border-bottom: none;
      background-color: #EFF2F7 !important;
    }

    :deep(th), :deep(td) {
      border-top: unset;
      padding: 20px;
    }

    :deep(tbody tr) {
      &:hover {
        background: #EFF2F7;
      }
    }

    :deep(.table__stackholders) {
      padding: 15px 20px;
    }

    :deep(.table__actions) {
      text-align: right;
      padding: 15px 20px 15px 0px;
    }
  }

  &__dropdown {
    width: 10px;
    height: 24px;
    position: relative;

    :deep(.btn) {
      padding: 0;
      border: none !important;
      background-color: transparent !important;
    }

    .bx-dots-vertical-rounded {
      top: 50%;
      left: 50%;
      font-size: 24px;
      color: #292f42;
      position: absolute;
      transform: translate(-50%, -50%);
    }

    .dropdown-divider {
      margin: 5px 20px;
    }

    :deep(.dropdown-item) {
      padding: 5px 20px;

      i {
        margin-right: 10px;
      }
    }
  }
}

:deep(.table td) {
  vertical-align: middle;
}

:deep(.table__disabled-row),
:deep(.table__disabled-row:hover) {
  color: #BBB;
}
</style>
